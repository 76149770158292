import React, { useState } from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import './register-app.css';

const RegisterAppPage = () => {
  const [updatePressed, setUpdatePressed] = useState(false);
  const [updateShown, setUpdateShown] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "register-app-images/register-app-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 621, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      guide: file(relativePath: { eq: "register-app-images/register-app-guide.png" }) {
        childImageSharp {
          fluid(maxWidth: 621, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const installApp = async () => {
    window.open(
      'itms-services:/?action=download-manifest&url=https://lcdev-patronscan-releases.s3.ca-central-1.amazonaws.com/ps-guest-register/6f47dd6e-441a-409f-800e-59eb84d47aad/69a0d23e-c6d2-411d-878f-2f038a274f6a/latest/ps-register.plist',
      '_self',
    );
    return true;
  };

  const handleInstall = async (event) => {
    event.preventDefault();

    console.log('install');

    // Run the install and record if update URL was opened
    setUpdateShown(await installApp());

    setUpdateLoading(true);

    setTimeout(async () => {
      setUpdateLoading(false);

      // Record that button was pressed regardless if successful
      setUpdatePressed(true);
    }, 8000);
  };

  let title;
  let instructions;

  if (updateLoading) {
    title = 'Downloading...';
    instructions = 'Please tap "Install" when prompted.';
  } else if (updatePressed && updateShown) {
    title = 'App Downloaded!';
    instructions = '';
  } else {
    title = 'Patronscan Guest Register App';
    instructions = 'Tap the button below to get started:';
  }

  return (
    <div className="register-app-container">
      <div className="register-app-hero-image-container">
        <Img className="register-app-hero-image" fluid={data.hero.childImageSharp.fluid} />
      </div>

      <div className="register-app-content">
        <h1 className="register-app-title">{title}</h1>
        <h1 className="register-app-instructions">{instructions}</h1>
        {updatePressed && updateShown ? (
          <div className="register-app-guide">
            <div className="register-app-guide-text">
              To complete the installation, open "Settings" on your phone and select "General" &gt;
              "Device Management" &gt; "Servall Data Systems". Then press "Trust".
            </div>
            <Img className="register-app-guide-image" fluid={data.guide.childImageSharp.fluid} />
            <div className="register-app-guide-text">
              Start the "Guest Register" app from your home screen and enjoy!
            </div>
          </div>
        ) : (
          <button
            disabled={updateLoading}
            onClick={handleInstall}
            className="register-app-download-button"
          >
            Install for iOS
          </button>
        )}
      </div>
    </div>
  );
};

export default RegisterAppPage;
